.social-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  &--footer {
    align-items: flex-start;
  }
}

.social-list__item {
  &:not(:last-child) {
    margin-right: 9.5px;
  }

  @media screen and (min-width: 1280px) {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
  &--footer {
    margin-bottom: 34px;
    @media screen and (min-width: 768px) {
      margin-bottom: 51px;
    }
    @media screen and (min-width: 1280px) {
      margin-bottom: 35px;
    }
  }
}
.social-list__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  transform: translateZ(0);

   &:hover .social-list__icon,
  &:focus .social-list__icon {
    fill: $button-hover-background-color;
    
    animation-name: hvr-pulse-grow;
    animation-duration: 550ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
@keyframes hvr-pulse-grow {
  to {
    transform: scale(1.4);
  }
}


  .social-list__icon {
    fill: $main-text-color;
    height: 15px;
    transition: fill $transition-background-color;
  }

  &--footer {
    align-items: flex-start;
  }
  &--twitter {
    width: 19px;
  }
  &--instagramm {
    width: 15px;
  }
  &--facebook {
    width: 8px;
  }
  @media screen and (min-width: 1280px) {
    height: 19px;

    &--twitter {
      width: 24px;
    }
    &--instagramm {
      width: 19px;
    }
    &--facebook {
      width: 10px;
    }
  }
}

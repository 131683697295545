.scroll-up {
  display: none;
  &__button {
    display: none;
  }
  @media screen and (min-width: 1280px) {
    z-index: 20;
    position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);

    width: 1000px;

    &__button {
      z-index: 10;
      position: absolute;
      display: block;
      right: 15px;
      bottom: 20px;
      min-width: 40px;
      min-height: 40px;
      padding: 5px;
      border-radius: 50%;
      animation-iteration-count: infinite;
      animation-duration: 2500ms;
      animation-name: blink;
    }
  }
}

$transition-menu-opacity: $transition-background-color;
.menu__container {
  z-index: 7;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.mobile-and-tablet-menu {
  z-index: 6;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  min-width: 320px;
  text-align: center;
  overflow-y: scroll;
  opacity: 1;
  background-color: $menu-background-color;
  backdrop-filter: $menu-backdrop-filter;
  transition: opacity $transition-menu-opacity;

  @media screen and (max-width: 1279.98px) {
    width: 100vw;
    height: 100%;
  }

  @media screen and (min-width: 1280px) {
    display: none;
  }

  &.is-close {
    opacity: 0;
    pointer-events: none;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 45px;
    }
  }
}
.mobile-and-tablet-menu__logo {
  display: block;
  padding-top: 25px;
  margin-bottom: 40px;

  &-icon {
    pointer-events: none;
    height: 85px;
    width: 190px;
  }
    &:hover .icon-logo,
    &:focus .icon-logo {
      fill: $menu-hover-text-color;
    }
  }


.mobile-and-tablet-menu__link {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2083;
  transition: color $transition-color;

  &:hover,
  &:focus {
    color: $menu-hover-text-color;
  }

  &:active {
    color: $menu-current-text-color;
  }
}

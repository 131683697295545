.header__container {
  text-align: center;

  @media screen and (min-width: 768px) {
    text-align: left;
  }
}

.header__navigation {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding-top: 10px;
  margin-bottom: 30px;

  @media screen and (min-width: 768px) {
    padding-top: 25px;
    justify-content: start;
    margin-bottom: 10px;
  }

  @media screen and (min-width: 1280px) {
    padding-top: 30px;
    margin-bottom: 60px;
  }
}

.header__logo {
  @media screen and (min-width: 1280px) {
    margin-right: 40px;
  }

  &-icon {
    height: 43px;
    width: 96px;

    @media screen and (min-width: 1280px) {
      height: 71px;
      width: 158px;
    }
  }
}

.header__content {
  max-width: 225px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @media screen and (min-width: 768px) {
    margin-left: 0;
    text-align: left;
  }

  @media screen and (min-width: 1280px) {
    max-width: 490px;
  }
}

.main-title {
  margin-bottom: 5px;
  font-family: Caveat Brush, sans-serif;
  font-size: 30px;
  font-weight: inherit;
  line-height: $main-title-line-height;

  @media screen and (min-width: 1280px) {
    margin-bottom: 15px;
    font-size: 64px;
    line-height: $desktop-main-title-line-height;
  }
}

.header__subtitle {
  font-weight: 500;
  margin-bottom: 35px;

  @media screen and (min-width: 768px) {
    margin-bottom: 25px;
  }

  @media screen and (min-width: 1280px) {
    font-size: 24px;
    line-height: 1.2083;
    margin-bottom: 30px;
  }
}

.header__button {
  margin-bottom: 45px;

  @media screen and (min-width: 768px) {
    margin-bottom: 30px;
  }

  @media screen and (min-width: 1280px) {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 768px) {
  .header__social-list {
    justify-content: left;
  }
}

.background-first__top {
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  @media screen and (max-width: 767.9px) {
    height: 460.51px;
    min-width: 320px;
    background-image: url(/images/hero/mobile/donuts-mobile.png),
      url(/images/background/mobile/bg-mobile.png);
    background-position: top left, bottom;
    background-size: 130px, cover;
    @media (min-device-pixel-ratio: 2),
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi),
      (min-resolution: 2dppx) {
      background-image: url(/images/hero/mobile/donuts-mobile@2x.png),
        url(/images/background/mobile/bg-mobile@2x.png);
    }
  }
  @media screen and (min-width: 768px) {
    height: 622px;
    background-image: url(/images/background/tablet/bg-tablet.png);
    background-position: bottom;
    background-size: cover;
    @media (min-device-pixel-ratio: 2),
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi),
      (min-resolution: 2dppx) {
      background-image: url(/images/background/tablet/bg-tablet@2x.png);
    }
  }
  @media screen and (min-width: 1280px) {
    height: 1078px;
    background-image: url(/images/hero/hero-desktop/spoon-desktop.png),
      url(/images/hero/hero-desktop/donuts-desktop.png),
      url(/images/background/desktop/bg-desktop.png);
    background-position: left top 165px, top right, bottom;
    background-size: 142px, 719px, cover;
    background-color: $registration-form-background;
    @media (min-device-pixel-ratio: 2),
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi),
      (min-resolution: 2dppx) {
      background-image: url(/images/hero/hero-desktop/spoondesktop@2x.png),
        url(/images/hero/hero-desktop/donuts-desktop@2x.png),
        url(/images/background/desktop/bg-desktop@2x.png);
    }
  }
}
.header__container {
  @media screen and (min-width: 768px) and (max-width: 1279.9px) {
    &::before {
      position: absolute;
      content: '';
      top: 0;
      right: 20px;
      width: 378px;
      height: 396px;
      pointer-events: none;
      background-image: url(/images/hero/hero-tablet/donuts-tablet.png);
      background-position: top right 69px;
      background-size: 377px;
      background-repeat: no-repeat;
      @media (min-device-pixel-ratio: 2),
        (-webkit-min-device-pixel-ratio: 2),
        (min-resolution: 192dpi),
        (min-resolution: 2dppx) {
        background-image: url(/images/hero/hero-tablet/donutstablet@2x.png);
      }
    }
  }
}
.about-us__wrapper {
  &::before {
    position: absolute;
    content: '';
    top: -308px;
    width: 100%;
    height: 309px;
    pointer-events: none;
    background-image: url(/images/about/mobile/pic-mobile.png);
    background-position: center;
    background-size: 290px;
    background-repeat: no-repeat;
    @media (min-device-pixel-ratio: 2),
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi),
      (min-resolution: 2dppx) {
      background-image: url(/images/about/mobile/pic-mobile@2x.png);
    }

    @media screen and (min-width: 768px) {
      top: 0;
      right: 357px;
      width: 330px;
      height: 352px;
      background-image: url(/images/about/tablet/pic-tablet.png);
      background-size: 330px;
      background-repeat: no-repeat;
      @media (min-device-pixel-ratio: 2),
        (-webkit-min-device-pixel-ratio: 2),
        (min-resolution: 192dpi),
        (min-resolution: 2dppx) {
        background-image: url(/images/about/tablet/pic-tablet@2x.png);
      }
    }
    @media screen and (min-width: 1280px) {
      position: absolute;
      z-index: 0;
      top: -72px;
      right: 485px;
      width: 512px;
      height: 546px;
      background-image: url(/images/about/desktop/pic-desktop.png);
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
      @media (min-device-pixel-ratio: 2),
        (-webkit-min-device-pixel-ratio: 2),
        (min-resolution: 192dpi),
        (min-resolution: 2dppx) {
        background-image: url(/images/about/desktop/pic-desktop@2x.png);
      }
    }
  }
}

.background-first-bottom {
  max-height: 1215px;
  min-width: 320px;
  margin-left: auto;
  margin-right: auto;
  background-image: url(/images/background/mobile/bg2-mobile.png);
  background-repeat: no-repeat;
  background-position: top 11px center;
  background-size: cover;
  @media (min-device-pixel-ratio: 2),
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi),
    (min-resolution: 2dppx) {
    background-image: url(/images/background/mobile/bg2-mobile@2x.png);
  }
  @media screen and (min-width: 768px) {
    max-height: 1260px;
    background-image: url(/images/background/tablet/bg2-tablet.png);
    background-position: top center;
    background-size: cover;
    @media (min-device-pixel-ratio: 2),
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi),
      (min-resolution: 2dppx) {
      background-image: url(/images/background/tablet/bg2-tablet@2x.png);
    }
  }
  @media screen and (min-width: 1280px) {
    max-height: 1499px;
    background: url(/images/background/desktop/bg2-desktop.png);
    background-position: top center;
    background-size: cover;
    @media (min-device-pixel-ratio: 2),
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi),
      (min-resolution: 2dppx) {
      background-image: url(/images/background/desktop/bg2-desktop@2x.png);
    }
  }
  &__footer {
    background-image: url(/images/footer/mobile/flour-mobile.png);
    background-position: right bottom;
    background-size: 87px 64px;
    background-repeat: no-repeat;
    @media (min-device-pixel-ratio: 2),
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi),
      (min-resolution: 2dppx) {
      background-image: url(/images/footer/mobile/flour-mobile@2x.png);
    }
    @media screen and (min-width: 768px) {
      background-image: url(/images/footer/tablet/flour-tablet.png);
      background-position: right bottom;
      background-size: 147px 144px;
      background-repeat: no-repeat;
      @media (min-device-pixel-ratio: 2),
        (-webkit-min-device-pixel-ratio: 2),
        (min-resolution: 192dpi),
        (min-resolution: 2dppx) {
        background-image: url(/images/footer/tablet/flour-tablet@2x.png);
      }
    }
    @media screen and (min-width: 1280px) {
      background: url(/images/footer/desktop/flour-desktop.png);
      background-position: right bottom;
      background-size: 319px 184px;
      background-repeat: no-repeat;
      @media (min-device-pixel-ratio: 2),
        (-webkit-min-device-pixel-ratio: 2),
        (min-resolution: 192dpi),
        (min-resolution: 2dppx) {
        background-image: url(/images/footer/desktop/flour-desktop@2x.png);
      }
    }
  }
}

.regisraton-container {
  &::before {
    @media screen and (min-width: 768px) {
      position: absolute;
      content: '';
      top: -55px;
      left: -76px;
      width: 235px;
      height: 255px;
      background-image: url(/images/registration/tablet/regdonut-tablet.png);
      background-size: 235px;
      background-position: top center;
      background-repeat: no-repeat;
      @media (min-device-pixel-ratio: 2),
        (-webkit-min-device-pixel-ratio: 2),
        (min-resolution: 192dpi),
        (min-resolution: 2dppx) {
        background-image: url(/images/registration/tablet/regdonut-tablet@2x.png);
      }
    }
    @media screen and (min-width: 1280px) {
      top: -49px;
      left: -157px;
      width: 257px;
      height: 255px;
      background-image: url(/images/registration/desktop/regdonut-desktop.png);
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
      @media (min-device-pixel-ratio: 2),
        (-webkit-min-device-pixel-ratio: 2),
        (min-resolution: 192dpi),
        (min-resolution: 2dppx) {
        background-image: url(/images/registration/desktop/regdonut-desktop@2x.png);
      }
    }
  }
  &::after {
    pointer-events: none;
    @media screen and (min-width: 768px) {
      position: absolute;
      content: '';
      z-index: 0;
      top: 15px;
      right: -66px;
      width: 280px;
      height: 300px;
      background-image: url(/images/registration/tablet/confetti-tablet.png);
      background-size: 280px;
      background-position: top center;
      background-repeat: no-repeat;
      @media (min-device-pixel-ratio: 2),
        (-webkit-min-device-pixel-ratio: 2),
        (min-resolution: 192dpi),
        (min-resolution: 2dppx) {
        background-image: url(/images/registration/tablet/confetti-tablet@2x.png);
      }
    }
    @media screen and (min-width: 1280px) {
      top: -45px;
      right: -218px;
      width: 589px;
      height: 618px;
      background-image: url(/images/registration/desktop/confetti-desktop.png);
      background-size: 589px 594px;
      background-position: top center;
      background-repeat: no-repeat;
      @media (min-device-pixel-ratio: 2),
        (-webkit-min-device-pixel-ratio: 2),
        (min-resolution: 192dpi),
        (min-resolution: 2dppx) {
        background-image: url(/images/registration/desktop/confetti-desktop@2x.png);
      }
    }
  }
}

// ---------------------контейнер секциии регистрация------
.registration {
  padding-top: 20px;
  padding-bottom: 25px;
  @media screen and (min-width: 768px) {
    padding-top: 115px;
    padding-bottom: 51px;
  }
  @media screen and (min-width: 1280px) {
    padding-top: 145px;
    padding-bottom: 77px;
  }
}
.regisraton-container {
  width: 100%;
  position: relative;
  @media screen and (min-width: 768px) {
    padding: 0;
    text-align: center;
  }
}

// ---------заголовок секции------

.title--regisraton {
  left: 50%;
  top: -14px;
  z-index: 5;
  transform: translate(-50%, 50%);
  position: absolute;
  padding: 0;
  margin: 0;
  display: block;
  width: 106px;
  height: 65px;
  @media screen and (min-width: 768px) {
    top: -110px;
  }
  @media screen and (min-width: 1280px) {
    top: -100px;
    left: 48%;
  }
}

// ---------контейнер для контента и формы------

.regisraton-container__content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 510px;
  width: 290px;
  box-shadow: $regisraton-container-box-shadow;
  border-radius: 150px 150px 0px 0px;
  padding-bottom: 47px;
  background-color: $registration-form-background;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: 768px) {
    width: 634px;
    height: 260px;
    border-radius: 150px 150px 150px 150px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (min-width: 1280px) {
    width: 822px;
  }
}

// ---------КОНТЕЙНЕР ДЛЯ ВРЕМЕНИ И ЦЕНЫ------
.regisraton-container__content-inform {
  padding-top: 91px;
  padding-bottom: 20px;
  border-bottom: 1px solid $input-text-color;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 11px;
  @media screen and (min-width: 768px) {
    width: 179px;
    height: 190px;
    padding: 0;
    margin: 0;
    border: none;
    border-right: 1px solid $input-text-color;
    padding-top: 16px;
    padding-bottom: 74px;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 30px;
    margin-right: 30px;
  }
  @media screen and (min-width: 1280px) {
    width: 264px;
    height: 190px;
    padding: 0;
    margin: 0;
    border: none;
    border-right: 1px solid $input-text-color;
    padding-top: 16px;
    padding-bottom: 69px;
    padding-right: 30px;
    margin-right: 30px;
  }
}

// ---------ВРЕМЯ РЕГИСТРАЦИИ------

.content-inform__item-time {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 114px;
  height: 44px;
  margin-bottom: 25px;
  font-family: $main-font;
  font-weight: 500;
  font-size: 18px;
  line-height: $desktop-main-line-height;
  color: $main-text-color;
  text-align: center;
  @media screen and (min-width: 768px) {
    margin-bottom: 34px;
    margin-left: 35px;
  }
  @media screen and (min-width: 1280px) {
    margin-right: 0;
    margin-left: 107px;
    width: 127px;
    height: 48px;
    font-size: 20px;
    margin-bottom: 30px;
  }
}

// ---------Цена------

.content-inform__item-price {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50px;
  height: 22px;
  font-family: $main-font;
  font-weight: 500;
  font-size: 18px;
  line-height: $desktop-main-line-height;
  color: $main-text-color;
  text-align: center;

  @media screen and (min-width: 768px) {
    margin-left: 99px;
  }
  @media screen and (min-width: 1280px) {
    margin-left: 179px;
    width: 55px;
    height: 24px;
  }
}

// ---------ФОРМА РЕГИСТРАЦИИ-- --------
.form-registration {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  margin: 0;
  &__input {
    font-family: Montserrat;
    color: $input-text-color;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.25;
    &::-webkit-input-placeholder {
      color: $input-text-color;
    }
    &:-ms-input-placeholder {
      color: $input-text-color;
    }
    &::-ms-input-placeholder {
      color: $input-text-color;
    }
    &::placeholder {
      color: $input-text-color;
    }
  }
  @media screen and (min-width: 768px) {
    width: 455px;
    height: 100%;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 54px;
    padding-bottom: 53px;
  }
  @media screen and (min-width: 1280px) {
    margin-left: 0;
  }
}

// ---------ФОРМА ЗАГОЛОВОК-- --------

.form-registration__title {
  padding-left: 30px;
  padding-right: 30px;
  width: 265px;
  height: 40px;
  font-family: $main-font;
  font-weight: 400;
  font-size: 16px;
  line-height: $main-line-height;
  color: $main-text-color;
  text-align: center;
  margin-bottom: 17px;
  @media screen and (min-width: 768px) {
    padding: 0;
    width: 234px;
    padding-right: 20px;
    text-align: start;
  }
  @media screen and (min-width: 1280px) {
    padding-right: 15px;
    margin-bottom: 13px;
    font-size: 18px;
  }
}

// ---------ФОРМА input-- --------

// -----------ОБЩИЕ СТИИ НА LABEL--------

.form-registration__item-label {
  height: 23px;
  border: none;
  &--name {
    margin-bottom: 25px;
    @media screen and (min-width: 768px) {
      margin-bottom: 15px;
    }
  }
  &--tel {
    margin-bottom: 25px;
    @media screen and (min-width: 768px) {
      margin-bottom: 15px;
    }
  }
  &--mail {
    margin-bottom: 37px;
    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
  }
}

// -----------ОБЩИЕ СТИИ НА INPUT--------

.form-registration__item-input {
  width: 265px;
  border: none;
  margin-bottom: 2px;
  transition: transform $transition-transform;
  transform: scale(1);
  border-bottom: 0.1px solid $pagination-color;
  background-color: transparent;
  @media screen and (min-width: 768px) {
    width: 234px;
    border-bottom: 1px solid $pagination-color;
  }
  &:hover,
  &:focus {
    padding-left: 10px;
    font-weight: 500;
    color: $main-text-color;
    outline: none;
    border-color: $button-click-background-color;
    transform: scale(1.1);
    border-left: 1px solid $pagination-color;
    border-right: 1px solid $pagination-color;
    border-radius: 5px;
    box-shadow: $form-registration-input-box-shadow;
  }
  &:not(:placeholder-shown) {
    color: $main-text-color;
  }
  &:not(:placeholder-shown):required:invalid {
    background-color: $registration-form-input-invalid;
    padding-left: 15px;
  }
  &:not(:placeholder-shown) {
    color: $main-text-color;
  }
}

// ----------------Кнопка регистрации
.form-registration__button {
  @media screen and (min-width: 768px) {
    position: absolute;
    top: 110px;
    right: 21px;
  }
  @media screen and (min-width: 1280px) {
    top: 103px;
    right: 70px;
  }
}

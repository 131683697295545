.contact__text {
  line-height: 4.06;
  text-align: center;
}

.contact__label {
  width: 265px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;

  @media screen and (min-width: 768px) {
    width: 410px;
    margin-bottom: 20px;
  }
  @media screen and (min-width: 1280px) {
    width: 430px;
  }
}

.contact__input {
  width: inherit;
  height: 22px;
  border: none;
  border-bottom: 1px solid $pagination-color;
  background-color: transparent;

  transform: scale(1);
  transition: transform $transition-background-color;
  &::placeholder {
    color: $input-text-color;
  }
  &:not(:placeholder-shown) {
    color: $main-text-color;
  }

  &:not(:placeholder-shown):required:invalid {
    background-color: $registration-form-input-invalid;
  }

  &:hover,
  &:focus {
    transform: scale(1.1);
    outline: none;
    font-weight: 500;
    padding-left: 10px;
    border-left: 1px solid $pagination-color;
    border-right: 1px solid $pagination-color;
    border-radius: 5px;
    box-shadow: $form-registration-input-box-shadow;
  }
}

.contact__textarea {
  resize: none;
}

[data-tooltip] {
  position: relative;

  &::before,
  &::after {
    line-height: 1;
    user-select: none;
    pointer-events: none;
    position: absolute;
    display: none;
    opacity: 0;
    text-transform: none;
    font-size: 0.9em;
  }

  &::before {
    content: '';
    z-index: 1;
    border: 5px solid transparent;
  }

  &::after {
    content: attr(data-tooltip);
    z-index: 1000;
    font-family: Montserrat, sans-serif;
    text-align: center;
    min-width: 3em;
    max-width: 21em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1ch 1.5ch;
    border-radius: 0.3ch;
    box-shadow: $contact-textareabox-shadow;
    background: $input-text-color;
    color: $button-text-color;
  }

  &:hover::before,
  &:hover::after {
    display: block;
  }

  &:not([flow])::before,
  &[flow^='up']::before {
    bottom: 100%;
    border-bottom-width: 0;
    border-top-color: #333;
  }
  &:not([flow])::after,
  &[flow^='up']::after {
    bottom: calc(100% + 5px);
  }
  &:not([flow])::before,
  &:not([flow])::after,
  &[flow^='up']::before,
  &[flow^='up']::after {
    left: 50%;
    transform: translate(-50%, -0.5em);
  }
  &:not([flow]):hover::before,
  &:not([flow]):hover::after,
  &[flow^='up']:hover::before,
  &[flow^='up']:hover::after {
    animation: tooltips-vert 300ms ease-out forwards;
  }
}

@keyframes tooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: 0.9;
    transform: translate(0, -50%);
  }
}

.contact__label {
  margin-left: auto;
  margin-right: auto;
}

.contact__button {
  margin: 10px auto 0px auto;
  display: block;
  text-align: center;
}

.desktop-menu {
  display: none;
  @media screen and (min-width: 1280px) {
    display: inline-flex;
  }
}

.desktop-menu__item:not(:last-child) {
  margin-right: 20px;
}

.desktop-menu__link {
  font-size: 18px;
  line-height: $desktop-main-line-height;
  transition: color $transition-color;

  &:hover,
  &:focus {
    color: $menu-hover-text-color;
  }
}

.footer {
  padding-top: 47px;
  padding-bottom: 19px;
  @media screen and (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 21px;
  }
  @media screen and (min-width: 1280px) {
    padding-top: 61px;
    background: none;
  }
}

.footerbox {
  display: block;
}

.address-block__list {
  font-style: normal;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.address-block__link {
  display: flex;
  align-items: center;
  transition: $transition-background-color;
  &:hover,
  &:focus {
    color: $menu-hover-text-color;
    animation-name: hvr-button;
    animation-duration: 900ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  @keyframes hvr-button {
    to {
      transform: scale(1.1);
    }
  }
}
.address-block__icon {
  display: block;
  margin-right: 3px;
  fill: currentColor;
}

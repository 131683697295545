.button {
  display: inline-flex;
  justify-content: center;
  min-width: 145px;
  min-height: 40px;
  padding-top: 9px;
  padding-bottom: 8px;
  color: $button-text-color;
  background: $button-background-color;
  font-family: $second-font;
  font-weight: 400;
  font-size: $main-font-size-desktop;
  line-height: 1.2;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: transform $transition-transform;
  transform: scale(1);
  &:hover {
    background-color: $menu-hover-text-color;
    box-shadow: $button-box-shadow;
    animation-name: hvr-button;
    animation-duration: 900ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  @keyframes hvr-button {
    to {
      transform: scale(1.1);
    }
  }
  &:focus {
    background-color: $button-click-background-color;
    border-color: $menu-hover-text-color;
    transition: transform $transition-transform;
    transform: scale(1.05);
    box-shadow: $button-box-shadow;
  }

  @media screen and (min-width: 1280px) {
    padding-top: 7px;
    padding-bottom: 7px;
    min-width: 158px;
    min-height: 45px;
    font-size: 24px;
  }
}

.section-button {
  display: inline-flex;
  font-family: Caveat Brush;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 3.61;
  text-align: center;
  color: $button-background-color;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: color $transition-background-color;
  &:hover {
    color: $menu-hover-text-color;
  }
  &:focus {
    color: $button-click-background-color;
  }
}

.button-close {
  z-index: 3;
}
.span--button {
  display: flex;
}

.about-us {
  padding-top: 282px;
  @media screen and (min-width: 768px) {
    padding-top: 80px;
  }
  @media screen and (min-width: 1280px) {
    padding-top: 155px;
  }
}
.about-us__wrapper {
  position: relative;
  @media screen and (max-width: 767px) {
    margin-right: auto;
    margin-left: auto;
  }
  @media screen and (min-width: 768px) {
    width: 336px;
    margin-left: auto;
  }
  @media screen and (min-width: 1280px) {
    width: 392px;
    line-height: 1.35;
  }
}

.about-us__description-indent-size {
  margin-bottom: 10px;
  @media screen and (min-width: 768px) {
    margin-bottom: 15px;
    @media screen and (min-width: 1280px) {
      margin-bottom: 21px;
    }
  }
  &--last {
    display: block;
    margin-top: 10px;
    @media screen and (min-width: 768px) {
      margin-top: 15px;
    }
    @media screen and (min-width: 1280px) {
      margin-top: 21px;
    }
  }
}

@media screen and (max-width: 1279.9px) {
  .about-us__input#inputHiddenContent3,
  #inputShownContent3 {
    display: none;
  }

  .about-us__span#spanHiddenContent3 {
    display: none;
  }

  .about-us__input#inputShownContent3:checked ~ .about-us__span#spanHiddenContent3 {
    display: block;
  }

  .about-us__input#inputHiddenContent3:checked ~ .about-us__span#spanHiddenContent3 {
    display: none;
  }

  .about-us__input#inputShownContent3:checked ~ #myReadMore .about-us__description--hidden {
    display: none;
  }
}
@media screen and (min-width: 1280px) {
  .about-us__description-seen {
    display: none;
  }
  .section-button {
    display: none;
  }
}

.program {
  @media screen and (min-width: 1280px) {
    padding-top: 30px;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767.98px) {
  .program-flexbox .program-wrapper {
    display: block;
  }
}

.program-list__item::before {
  content: '\003E';
  margin-right: 3px;
}

.program__description-indent-size {
  margin-bottom: 10px;
  @media screen and (min-width: 768px) {
    margin-bottom: 15px;
    @media screen and (min-width: 1280px) {
      margin-bottom: 21px;
    }
  }
  &--last {
    display: block;
    margin-top: 10px;
    @media screen and (min-width: 768px) {
      margin-top: 15px;
    }
    @media screen and (min-width: 1280px) {
      margin-top: 21px;
    }
  }
}
.program-list__item--margin {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.program__title {
  @media screen and (min-width: 1280px) {
    margin-bottom: 10px;
  }
}

.program-picture-list {
  @media screen and (max-width: 767.98px) {
    width: 290px;
    margin-left: auto;
    margin-right: auto;
  }
}

.program-picture-list__img {
  position: absolute;
  bottom: 75%;
  left: 25%;

  animation: rollIn 2500ms cubic-bezier(0.68, -0.55, 0.265, 1.55) both,
    rotateOut 2500ms linear 2500ms infinite forwards;
}

@keyframes rollIn {
  0% {
    transform: translateX(-1000px) rotate(-720deg);
    opacity: 0;
  }
  100% {
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}

@keyframes rotateOut {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.program-picture-list__item {
  position: relative;
  display: flex;
  flex-basis: calc(100% - 40px / 2);
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 142px;
  max-width: 142px;

  text-align: center;
  background-color: $program-background;
  border-radius: 50%;

  @media screen and (min-width: 1280px) {
    max-width: 180px;
    height: 180px;
  }
}

.program-picture-list__item:not(:last-child) {
  margin-bottom: 57px;
  @media screen and (min-width: 1280px) {
    margin-bottom: 61px;
  }
}

.program-picture-list__title {
  font-family: Caveat Brush;
  font-size: 18px;
  line-height: 1.27;
  color: $secondary-text-color;

  @media screen and (min-width: 1280px) {
    font-size: 24px;
    line-height: 1.25;
  }
}

.program-picture-list__info {
  font-weight: inherit;
  font-size: 14px;
  line-height: 1.21;
  color: $secondary-text-color;

  @media screen and (min-width: 1280px) {
    font-size: 16px;
    line-height: 1.25;
  }
}

.program-content-box {
  flex-basis: calc(100% - 40px / 2);
  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: 768px) {
    align-content: flex-start;
  }
}

@media screen and (min-width: 768px) {
  .program-flexbox {
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .program-content-box--center {
    justify-content: center;
    align-content: flex-start;
  }
}

.program-picture-list__item--transform {
  transform: translateY(25px);

  @media screen and (min-width: 1280px) {
    transform: translateY(56px);
  }
}

.program-picture-list__item--margin {
  margin-right: 6px;

  @media screen and (min-width: 768px) {
    margin-right: 15px;
  }

  @media screen and (min-width: 1280px) {
    margin-right: 32px;
  }
}

@media screen and (min-width: 1280px) {
  .program-picture-list__icon--first {
    width: 25px;
    height: 23px;
  }

  .program-picture-list__icon--second {
    width: 26px;
    height: 23px;
  }

  .program-picture-list__icon--third {
    width: 25px;
    height: 25px;
  }

  .program-picture-list__icon--fourth {
    width: 31px;
    height: 21px;
  }
}

.program__section-button {
  margin-bottom: 40px;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
}

// пример
@media screen and (max-width: 1279.9px) {
  .program__input#inputHiddenContent4,
  #inputShownContent4 {
    display: none;
  }

  .program__span#spanHiddenContent4 {
    display: none;
  }

  .program__input#inputShownContent4:checked ~ .program__span#spanHiddenContent4 {
    display: block;
  }

  .program__input#inputHiddenContent4:checked ~ .program__span#spanHiddenContent4 {
    display: none;
  }

  .program__input#inputShownContent4:checked ~ #myReadMore1 .program__description--hidden {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .program__description-seen {
    display: none;
  }
  .section-button {
    display: none;
  }
}

.hamburger-button {
  z-index: 10;
  position: absolute;
  right: 15px;
  top: 20px;

  padding: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  background-color: rgba(254, 243, 228, 0.842);
  border-radius: 50%;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  margin: 0;
  overflow: visible;
  border: 0;

  &:hover,
  &:focus {
    .hamburger-button__inner,
    .hamburger-button__inner::before,
    .hamburger-button__inner::after {
      background-color: $button-hover-background-color;
    }
  }

  &.is-active {
    .hamburger-button__inner,
    .hamburger-button__inner::before,
    .hamburger-button__inner::after {
      background-color: $hamburger-line-active-color;
    }
  }

  @media screen and (min-width: 768px) {
    top: 25px;
  }

  @media screen and (min-width: 1280px) {
    display: none;
  }
}

.hamburger-button__box {
  width: $hamburger-line-width;
  height: $hamburger-line-height * 3 + $hamburger-line-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-button__inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-line-height / -2;

  &,
  &::before,
  &::after {
    width: $hamburger-line-width;
    height: $hamburger-line-height;
    background-color: $hamburger-line-color;
    border-radius: $hamburger-line-border-radius;
    position: absolute;
    transition: transform 150ms ease;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    top: ($hamburger-line-spacing + $hamburger-line-height) * -1;
  }

  &::after {
    bottom: ($hamburger-line-spacing + $hamburger-line-height) * -1;
  }
}

.hamburger-button--spin {
  .hamburger-button__inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    }

    &::after {
      transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.is-active {
    .hamburger-button__inner {
      transform: rotate(225deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}

.reviews {
  @media screen and (min-width: 1280px) {
    padding-top: 16px;
  }
}

//СЛАЙДЕР 1

.slider__foto {
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
}

// Рейтинг
.form-rating {
  display: flex;
  justify-content: center;
}

.rating {
  position: relative;
  display: inline-block;
  font-size: 24px;

  &::before {
    content: '\2606\2606\2606\2606\2606';
    display: block;
    color: $rating-color;
  }
}

.rating__items {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;
}

.rating__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
}

.rating__label {
  flex: 0 0 20%;
  height: 100%;
  cursor: pointer;
  color: $reviws-bg-color;
}

.rating__item:checked ~ .rating__label::before,
.rating__item + .rating__label:hover ~ .rating__label::before,
.rating__item + .rating__label:hover::before {
  content: '\2605';
  color: $rating-color;
}

// Имя
.slider__name {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;

  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }
}

// Центр-мод
.slider .slick-center {
  @media screen and (min-width: 768px) {
    padding-top: 20px;
  }

  @media screen and (min-width: 1280px) {
    padding-top: 30px;
  }

  & img {
    min-width: 110px;

    @media screen and (min-width: 768px) {
      min-width: 186px;
    }

    @media screen and (min-width: 1280px) {
      min-width: 234px;
    }
  }
}

// СЛАЙДЕР 2

.slick-initialized .slick-slide {
  text-align: center;
}

.slider-text__item {
  display: none;
  flex-direction: column;
  align-items: center;

  &--mobile {
    @media screen and (max-width: 767.9px) {
      display: inline-flex;
    }
  }

  &--tablet {
    @media screen and (min-width: 768px) and (max-width: 1279.9px) {
      display: inline-flex;
    }
  }

  &--desktop {
    @media screen and (min-width: 1280px) {
      display: inline-flex;
    }
  }
}

// Счетчик
.slider-text__counter {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  line-height: 1.2142;
  color: $pagination-color;

  @media screen and (min-width: 768px) {
    padding-top: 35px;
  }
}

// Стрелка влево
.slick-prev {
  top: 92%;
  left: 30%;
  transition: color $transition-background-color;

  @media screen and (min-width: 768px) {
    top: 91%;
    left: 37%;
  }

  @media screen and (min-width: 1280px) {
    left: 38%;
  }

  &:before {
    content: '\2014';
    font-family: $main-font;
    font-size: 35px;
    line-height: 1;
    opacity: 1;
    color: $pagination-color;
  }

  &:hover::before {
    content: '\27F5';
    color: $pagination-hover-color;
  }

  &:active::before {
    color: $pagination-click-color;
  }
}

// Стрелка вправо
.slick-next {
  top: 92%;
  right: 35%;
  transition: color $transition-background-color;

  @media screen and (min-width: 768px) {
    top: 90%;
    right: 40%;
  }

  &:before {
    content: '\2014';
    font-family: $main-font;
    font-size: 35px;
    line-height: 1;
    opacity: 1;
    color: $pagination-color;
  }

  &:hover::before {
    content: '\27F6';
    color: $pagination-hover-color;
  }

  &:active::before {
    color: $pagination-click-color;
  }
}

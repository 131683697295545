$main-background-color: #ffffff;
$main-background-color-desktop: #fffff9;

$main-text-color: #2e2520;
$secondary-text-color: #000000;
$main-font: Montserrat, sans-serif;
$main-font-weight: 400;
$main-font-size: 16px;
$main-font-size-desktop: 18px;
$main-title-line-height: 1.2667;
$main-line-height: 1.25;
$main-section-title-line-height: 2.71;
$desktop-main-line-height: 1.22;
$desktop-main-title-line-height: 1.0156;
$desktop-section-title-line-height: 1.81;
$button-text-color: #ffffff;
$button-background-color: #ab816c;
$button-hover-background-color: #876655;
$button-click-background-color: #674d40;
$button-border-radius: 25px;
$input-text-color: rgba(171, 129, 108, 0.5);
$menu-background-color: rgba(254, 243, 228, 0.9);
$menu-backdrop-filter: blur(4px);
$menu-hover-text-color: #876655;
$pagination-color: #ab816c;
$menu-current-text-color: #674d40;
$pagination-color: #ab816c;
$registration-form-main-border: 1px solid rgba(171, 129, 108, 0.5);
$registration-form-input-border: 1px solid #ab816c;
$registration-form-background: #fffff9;
$registration-form-box-shadow: -4px -4px 4px rgba(171, 129, 108, 0.15),
  4px 4px 4px rgba(171, 129, 108, 0.15);
$transition-transform: 250ms cubic-bezier(0.4, 0, 0.2, 1);
$transition-background-color: 250ms cubic-bezier(0.4, 0, 0.2, 1);
$transition-color: 250ms cubic-bezier(0.4, 0, 0.2, 1);
$transition-fill: 250ms cubic-bezier(0.4, 0, 0.2, 1);
$program-background: #fef3e4;
$program-box-shadow: 0px 4px 4px rgba(171, 129, 108, 0.25);
$second-font: Caveat Brush, sans-serif;
$registration-form-input-invalid: hsla(7, 100%, 50%, 0.3);

$rating-color: #ab816c;
$reviws-bg-color: #fef3e4;
$pagination-color: #ab816c;
$pagination-hover-color: #876655;
$pagination-click-color: #674d40;
$registration-form-border-color: rgba(171, 129, 108, 0.5);

$hamburger-hover-opacity: 0.7;
$hamburger-active-hover-opacity: 1;
$hamburger-line-width: 30px;
$hamburger-line-height: 2px;
$hamburger-line-spacing: 5px;
$hamburger-line-color: #2e2520;
$hamburger-line-hover-color: #876655;
$hamburger-line-border-radius: 1px;
$hamburger-line-active-color: #674d40;
$form-registration-input-box-shadow: 0px 8px 5px -5px rgba(49, 34, 6, 0.6);
$regisraton-container-box-shadow: -4px -4px 4px rgba(171, 129, 108, 0.15),
  4px 4px 4px rgba(171, 129, 108, 0.15);
$button-box-shadow: 0px 0px 10px 2px rgba(49, 34, 6, 0.75) inset;
$contact-textareabox-shadow: 0 1em 2em -0.5em rgba(49, 34, 6, 0.75);
